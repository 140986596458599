<template>
  <section class="pb-2" :style="step == 1 ? 'height: calc(100vh + 350px)' : ''">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary font-weight-bold"> Carstock </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>

            <b-breadcrumb-item active> Crea Carstock </b-breadcrumb-item>
            <b-breadcrumb-item v-if="step == 2" active>
              Pubblicazione offerta
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div v-if="step == 1" class="mt-2">
        <b-row>
          <b-col md="9"> </b-col>

          <b-col md="3">
            <div class="d-flex align-items-center mb-2">
              <span class="mr-1">Cerca</span>
              <b-form-input
                type="text"
                v-model="filter"
                size="md"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

        <b-table
          :fields="fields"
          :items="carProvider"
          :busy="loading"
          :current-page="currentPage"
          striped
          show-empty
          responsive
          @sort-changed="sortTable"
        >
          <template #table-busy>
            <div class="text-center text-primary-light my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Caricamento dati delle auto...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-center">
              <h5 class="text-primary-light my-2">
                Non ci sono auto da mostrare
              </h5>
            </div>
          </template>
          <template v-slot:head(selected)>
            <b-form-checkbox @change="toggleAll" />
          </template>
          <template v-slot:cell(selected)="row">
            <b-form-checkbox-group v-model="selected_auto">
              <b-form-checkbox :unchecked-value="null" :value="row.item" />
            </b-form-checkbox-group>
          </template>
          <template #cell(id)="data">
            {{ data.item.id_auto }}
          </template>
          <template #cell(anno)="data">
            {{ data.item.immatricolazione_anno }}
          </template>
          <template #cell(km)="data">
            {{ data.item.chilometraggio }}
          </template>
          <template #cell(prezzo_pubblico)="data">
            <span v-if="data.item.prezzo_pubblico">
              {{ data.item.prezzo_pubblico }}€
            </span>
          </template>
          <template #cell(prezzo_rivenditore)="data">
            <span v-if="data.item.prezzo_rivenditore">
              {{ data.item.prezzo_rivenditore }}€
            </span>
          </template>
        </b-table>
      </div>

      <div v-if="step == 2" class="mt-2">
        <b-img class="mb-5" src="@/assets/images/carstock-grafico.svg" fluid />

        <b-row>
          <b-col md="6">
            <h2 class="text-primary-light">
              Pannello di pubblicazione offerta
            </h2>

            <p class="mt-1">
              Di seguito potrai visionare l'<b>elenco delle auto selezionate</b>
              e scegliere di<br />
              deselezionarle per non includerle nel pacchetto. Ricorda di
              <b>assegnare</b> un <br />
              <b>nome</b> alla tua offerta ed un <b>prezzo</b> per lo stock che
              stai creando. Imposta <br />
              una <b>data di fine</b> per l'offerta.
            </p>
          </b-col>

          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group label="Nome nuovo stock">
                  <b-form-input
                    v-model="stock_name"
                    placeholder="Inserisci il nome della tua nuova offerta"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Data di fine dell'offerta">
                  <b-form-input
                    v-model="offer_end_date"
                    type="date"
                    placeholder="gg/mm/aaaa"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Prezzo stock">
                  <b-form-input
                    v-model="stock_price"
                    type="number"
                    placeholder="Inserisci il prezzo della tua nuova offerta"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr />

        <h5 class="my-2 text-primary-light">
          Auto selezionate da includere nello stock
        </h5>

        <b-table
          :fields="selected_fields"
          :items="selected_auto"
          :busy="loading"
          :per-page="15"
          striped
          show-empty
          responsive
        >
          <template #table-busy>
            <div class="text-center text-primary-light my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Caricamento dati delle auto...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-center">
              <h5>Non ci sono auto da mostrare</h5>
            </div>
          </template>
          <template #cell(selected)> </template>
          <template #cell(anno)="data">
            {{ data.item.immatricolazione_anno }}
          </template>
          <template #cell(km)="data">
            {{ data.item.chilometraggio }}
          </template>
          <template #cell(prezzo_pubblico)="data">
            <span v-if="data.item.prezzo_pubblico">
              {{ data.item.prezzo_pubblico }}€
            </span>
          </template>
          <template #cell(prezzo_rivenditore)="data">
            <span v-if="data.item.prezzo_rivenditore">
              {{ data.item.prezzo_rivenditore }}€
            </span>
          </template>
        </b-table>

        <div class="mt-5">
          <p class="text-primary">Riepilogo <b>Totale</b></p>
          <div class="d-flex justify-content-between text-primary">
            <h3 class="text-primary">TOTALE CARSTOCK:</h3>
            <h3 class="text-primary">{{ carstock_total }}€</h3>
          </div>
        </div>
      </div>
    </b-card>

    <div class="fixed-bottom bg-white p-2">
      <div class="text-right">
        <div>
          <b-button
            v-if="step > 1"
            variant="outline-primary-light mr-2"
            @click="step = 1"
            >Indietro</b-button
          >
          <b-button
            v-if="step == 1"
            variant="primary"
            @click="step = 2"
            :disabled="!state"
          >
            <b>Crea CarStock</b> con le auto selezionate
          </b-button>
          <b-button v-else variant="primary" @click="createCarStock">
            Crea CarStock
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BBreadcrumb,
  BBreadcrumbItem,
  BTable,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BBreadcrumb,
    BBreadcrumbItem,
    BTable,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BImg,
    BFormGroup,
    BFormInput,
  },

  data: () => ({
    step: 1,
    selected_auto: [],
    stock_name: "",
    stock_price: "",
    offer_end_date: "",

    fields: [
      {
        key: "selected",
      },
      {
        key: "id_auto",
        label: "id",
        sortable: true,
      },
      {
        key: "marca",
        sortable: true,
      },
      {
        key: "modello",
        sortable: true,
      },
      {
        key: "versione",
        sortable: true,
      },
      {
        key: "alimentazione",
        sortable: true,
      },
      {
        key: "anno",
        sortable: true,
      },
      {
        key: "km",
        sortable: true,
      },
      {
        key: "targa",
        sortable: true,
      },
      {
        key: "telaio",
        sortable: true,
      },
      {
        key: "prezzo_pubblico",
        sortable: true,
      },
      {
        key: "prezzo_rivenditore",
        sortable: true,
      },
    ],

    auto: [],
    filter: null,
    sortBy: "id_auto",
    sortDesc: false,

    //Helpers

    loading: false,
    currentPage: 1,
    perPage: 15,
  }),

  computed: {
    selected_fields() {
      return this.fields.slice(1);
    },

    carstock_total() {
      return this.selected_auto.reduce((accumulator, object) => {
        return (
          parseInt(accumulator) +
          parseInt(object.prezzo_rivenditore ? object.prezzo_rivenditore : 0)
        );
      }, 0);
    },

    state() {
      return this.selected_auto.length >= 1;
    },
  },

  methods: {
    toggleAll(checked) {
      this.selected_auto = checked ? this.auto.slice() : [];
    },

    async getAutoOnSale(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getAutoOnSale();
        this.auto = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async createCarStock() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("nome_stock", this.stock_name);
      request_data.append("prezzo_stock", this.stock_price);
      request_data.append(
        "array_id",
        this.selected_auto.map((a) => a.id_auto)
      );
      request_data.append("data_fine_offerta", this.offer_end_date);

      try {
        const response = await Requests.createCarStock(request_data);
        console.debug(response);
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async carProvider() {
      await this.getAutoOnSale();
      return this.auto.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>
